/* Dropdown content buttons */
.dropbtn {
  font-size: 19px;
  border: 0;
  outline: none;
  display: inline-block;
  text-align: left;
  opacity: .7;
  padding: 8px 16px;
  background-color: inherit;
  font-family: inherit; 
  margin: 0;
}
h2 {
  font-size: 19px;
    border: 0;
    outline: none;
    display: inline-block;
    text-align: left;
    opacity: .85;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.dropbtn:hover {
  opacity: 1;
}

.dropbtn:active {
  font-weight: bolder;
  box-shadow: 10px;
}

.activeDropbtn {
  display: none;
}

.dropdown:hover .dropdown_content {
    display: flex;
    flex-direction: column;
}

.icon {
  color: grey;
  font-size: 12px;
  margin-left: 5px;
}

.icon:hover {
  color: green;
}

.LangSelector {
  font-size: 16px;
  width: 100%;
  display: block;
  padding: 10px 20px;
  overflow: scroll;
  top: 60px;
  z-index: 1;
}

/* The dropdown container */
.selectbtn {
  background: #fafafc;
  margin: 10px 15px;
  overflow-y: auto;
  font-size: 19px;
  opacity: 1;
  border: 0;
  outline: none;
  display: inline;
  text-align: center;
  padding: 5px 5px;
  font-family: inherit;
  font-weight: bolder;
  left: 0px;
  position: relative;
}

.flag {
  height: 30px;
  width: 30px;
  top: 9px;
  border-radius: 50%;
  position: relative;
  left: -5px;
  border: 1px solid gray;
}

.selectbtn > .flag > span:nth-child(1) {
  display: none;
}

.dropdown_arrow {
  height: 6%;
  width: 6%;
  display: inline-flex;
  position: relative;
  left: 66px;
  top: 6px;
}

/* Dropdown content (hidden by default) */
.dropdown_content {
  display: none;
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  padding: 5px 15px;
  background-color: seashell;
  border-radius: 25px;
  outline: none;
  min-width: 160px;
  /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
  z-index: 11;
}
/*-------------- Mobile --------------*/
@media(max-width: 600px){
  .dropdown:hover .dropdown_content {
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;
    padding: 1px 5px;
    background-color: seashell;
    border-radius: 25px;
    outline: none;
    min-width: 160px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 11;
}
.selectbtn {
    background: #fafafc;
    margin: 7px 0px;
    overflow-y: auto;
    font-size: 16px;
    opacity: 1;
    border: 0;
    outline: none;
    display: inline;
    text-align: center;
    padding: 5px 21px;
    font-family: inherit;
    font-weight: bolder;
    left: 0px;
    position: relative;
}
.dropbtn{
  padding: 3px 16px;
}
}
/*---------- Small Mobile Screens ----------*/
@media(max-width: 500px){
  .dropdown:hover .dropdown_content {
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;
    right: 53%;
    padding: 1px 5px;  
    background-color: seashell;
    border-radius: 25px;
    outline: none;
    min-width: 160px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 11;
  }
  .dropbtn{
    padding: 3px 16px;
  }
}
