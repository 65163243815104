.TopNav {
    height: 20px; 
    position: fixed;
}

.LowNav {
    height: 20px;
    margin-top: auto;
    position: fixed;
}

.Layout {
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    grid-row-gap: 10px;
    height: 100%;
    width: 100%;
}

.LeftNav {
    grid-row-start: 1;
    grid-row-end: 1;
}

