h1 {
    font-size: 36px;
}
h2, h3 {
    font-size: 24px;
}
h4 {
    font-size: 20px;
}
.mainlayout {
    max-width: 1200px;
    margin:0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-column-gap: 50px;
    justify-items: center;
    background: #fafafc;
}
.NulangoMain {
    width: 100%;
    padding-top: 100px;
    margin-left: 100px;
    align-content: center;
}
.copyright {
    position: relative;
}

@media(min-width:1800px){
    .mainlayout {
        max-width: 1200px;
        margin:0 auto;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-column-gap: 100px;
        justify-items: center;
        background: #fafafc;
    }
}
/*----------------- Desktop Styles ------------------*/
@media(min-width: 1051px) {
.rightsidebarwrapper {
    display: none;
    position: relative;
    margin-right: 20px;
    margin-bottom: 15px;
}
.rightsidebarwrapper2 {
    position: relative;
    top: 176px;
    margin-right: 20px;
    margin-bottom: 15px;
    height: max-content;
}
.copyrightDesktop{
    display:block
}
    .FeedItems {
        text-align: center;
        padding-top: 200px;
        padding-left: 16px;
        background: #fafafc;
    }
    .form-control {
        width: 255px;
    }
    .toggle > span:nth-child(2){
        position: relative;
        top: -44px;
        left: 13px;
        font-size: 18px;
        height: 91px;
        z-index: 2;
    }
    .FooterContainer1{
        position: relative;
        display: block;
    }
    .FooterContainer1{
        position: relative;
        display: none;
    }
}

.toggle {
    display: block;
    background-color: #fafafc;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 50px;
    right: 0%;
    top: 55px;
    height: 97px;
}
/*---- Translation -----*/
.toggle > span {
    position: relative;
    top: -75px;
    left: 173px;
    font-size: 18px;
}
/*----- Transliteraion -----*/

/*---------------- Tablet Styles ----------------*/
@media (max-width: 1050px) and (min-width:601px){
    .toggle > span:nth-child(2){
        position: relative;
        top: 56px;
        left: 176px;
        font-size: 18px;
        height: 91px;
        z-index: 2;
}
    .toggle > span {
        position: relative;
        top: 24px;
        left: 336px;
        font-size: 18px;
    }
    .mainlayout {
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 50px;
        display: grid;
        justify-items: center;
        background: #fafafc;
    }
    .rightsidebarwrapper {
        top: 228px;
        margin-left: auto;
        position: relative;
        margin-right: auto;
    }
    .rightsidebarwrapper2 {
        display: none;
    }
    .copyright {
        position: relative;
        top: 189px;
    }
    .feedItems {
        border: 2px solid #d6d6d6;
        border-radius: 20px;
        font-size: 14px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: auto;
        max-width: 500px;
        width: 418px;
        min-width: 279px;
        padding-left: 15px;
        justify-content: center;
        position: relative;
        right: 5%;
        background: #fafafc;
    }
    .FooterContainer1{
        position: relative;
        display: block;
    }
    .FooterContainer2{
        position: relative;
        display: none;
    }
}
/*-------------------Mobile Styles -------------------*/
@media(max-width: 600px){
    .toggle{
        display: block;
        background-color: #fafafc;
        position: fixed;
        z-index: 8;
        width: 100%;
        height: 50px;
        right: 0%;
        top: 15px;
        height: 101px;
    }
    h2, h3 {
        font-size: 18px;
    }
    .navcontainer {

    }
    .toggle > span {
        position: relative;
        top: 40px;
        left: 163px;
        font-size: 18px;
        }
    .toggle > span:nth-child(2){
        position: relative;
        top: 65px;
        left: 4px;
        font-size: 18px;
        height: 91px;
        z-index: 2;
    }
    .mainlayout {
        grid-template-columns: 1fr;
        grid-column-gap: 50px;
        justify-items: center;
        background: #fafafc;
    }
    .comingSoon{
        display: none;
    }

    .rightsidebarwrapper2 {
        display: none;
    }
    .copyrightDesktop{
        display:none;
    }
    .FooterContainer2{
        position: relative;
        display: none;
    }
    .FooterContainer1{
        position: relative;
        display: block;
    }
}


.languageDropdown1 {
    flex-grow: 1;
}
.languageDropdown2 {
    flex-grow: 1;
}
@media(max-width:600px) and (min-width: 501px){
    .rightsidebarwrapper {
     top: 226px !important;
     left: 2px;
     position: relative;
 }
}
@media (max-width: 500px){
     .rightsidebarwrapper {
        top: 142px;
        left: 2px;
        position: relative;
    }
}