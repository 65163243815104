.toggle {
  font-family: 'Baloo 2';
}

.switch {
  display: inline-block;
  position: relative;
  display: inline-block;
  top: 3px;
  right: -14px;
  width: 47px;
  left: auto;
  height: 26px;
  margin-top: 100px;
  margin-bottom: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  /* display: flex; */
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  border-radius: 34px;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  border-radius: 50%;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  content: "";
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/*----------- Tablet Styles -------------*/
@media (max-width: 1050px){
  .switch {
    position: relative;
    display: inline-block;
    top: 3px;
    right: -14px;
    width: 47px;
    left: auto;
    height: 24px;
    margin-top: 6px;
    margin-bottom: 10px;
 }}
/*------------ Mobile Styles -------------*/
@media (max-width: 600px){
  .switch {
    position: relative;
    display: inline-block;
    top: 5px;
    right: -8px;
    width: 45px;
    left: auto;
    height: 21px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .slider:before  {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 1px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    border-radius: 50%;
    transition: .4s;
  }
  }