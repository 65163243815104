.Subscribe {
    display: block;
    background-color: #ffd17d;
    border-radius: 20px;
    border: 2px solid #f1b64b;
    align-items: center;
    text-align: left;
    color: black;
    font-size: 15px;
    padding: 15px 16px;
    width: 267px;
    height: auto;
    top: 158px;
    margin-bottom: 24px;
}

.Subscribe > h4 {
    font-size: 24px;
    margin-top: 3px;
    padding-top: 0px;
    margin-bottom: 18px;
    line-height: 1.1em;
    text-align: center;
}
@media (max-width: 600px){
    .Subscribe > h4 {
        font-size: 29px;
        margin-top: 3px;
        padding-top: 19px;
        padding-bottom: 5px;
        margin-bottom: 18px;
        line-height: 1.1em;
    }
}
.Subscribe > form > input[type=email] {
    font-size: 16px;
    height: 30px;
    border: 1px solid #d3d3d3;
    border-radius: 4px 0 0 4px;
    text-indent: 8px;
    padding-right: 15px;
    position: relative;
    bottom: 7px;
    width: 155px;
}
.Subscribe > form > button {
    height: 34px;
    position: relative;
    bottom: 7px;
    left: -2px;
    font-weight: 600;
    color: black;
    font-size: 14px;
    z-index: 1;
    border: 1px solid gray;
    border-radius: 0px 4px 4px 0px;
    background: white;
}
.Subscribe > form > button:hover {
    background: #F0F0F0;
}
@media(min-width:601px) and (max-width:1050px){
    .Subscribe {
        display: block;
        background-color: #ffd17d;
        border-radius: 20px;
        border: 2px solid #f1b64b;
        align-items: center;
        text-align: left;
        color: black;
        font-size: 15px;
        padding: 15px 16px;
        width: 87%;
        max-width: 500px;
        height: 126px;
        top: 158px;
        margin-bottom: 24px;
        margin-left: 2px;
    }
}
@media(max-width:1051) and (min-width: 601px) {
    .Subscribe > form > input[type=email] {
        position: relative;
        bottom: 7px;
        }
    }
@media(max-width:600px){
    .Subscribe {
        display: block;
        background-color: #ffd17d;
        border-radius: 20px;
        border: 2px solid #f1b64b;
        align-items: center;
        text-align: center;
        color: black;
        font-size: 12px;
        padding: 0px 16px;
        width: 87%;
        max-width: 500px;
        top: 300px;
        height: auto;
        margin-left: 2px;
    }
    .Subscribe > form > input[type=email] {
        font-size: 16px;
        height: 30px;
        border: 1px solid lightgray;
        border-radius: 4px 0px 0px 4px;
        text-indent: 3%;
        padding-right: 15px;
        position: relative;
        bottom: 7px;
        width: 150px;
        max-width: 50%;
        }
    } 
.heading_text {
    font-size: 20px;
    font-weight: bold;
}
.clear {
    font-size: 12px;
}
.button {
    background-color: red;
} 
