.About {
    display: block;
    background-color: #bee5fd;
    border-radius: 20px;
    border: 2px solid #83b6d5;
    align-items: center;
    text-align: center;
    color: black;
    font-size: 12px;
    padding: 10px 16px;
    width: 87%;
    max-width: 500px;
    top: 551px;
    height: auto;
    margin-left: 2px;
}
.About > h1 > p {
    font-size: 19px;
}

.contactUs {
    font-size:19px;
    text-align: center;
}
/* Was heading_text */
.header_text {
    font-size: 29px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    display: block;
}
@media (min-width: 601px) {
    /* Was heading_text */
.header_text {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    display: block;
 }
}
.aboutText {
    font-size: 20px;
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.contactUs {
    font-size: 20px;
    text-align: center;
}

@media(max-width: 600px){
    .About {
        display: block;
        background-color: #bee5fd;
        border-radius: 20px;
        border: 2px solid #83b6d5;
        align-items: center;
        text-align: left;
        color: black;
        font-size: 12px;
        padding: 10px 16px;
        width: 87%;
        max-width: 500px;
        top: 551px;
        height: auto;
        margin-left: 5px;
    }
}
