
.TopNavBar {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 4px;
  position: fixed;
  top: 0;
  height: 70px;
  z-index: 3;
  background-color: #FAFAFC;
  overflow: show;
  font-family: 'Baloo 2';
  padding-right: 100%;
  z-index:11;
  }
.TopNavBar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
  /* Style the links inside the navigation bar */
  .NavIcon {
    color: black;
    position: relative;
    float: right;
    padding: 10px 5px;
    margin: 10px;
  }

  /* Change the color of links on hover */
  .TopNavBar a:hover {
    color:#4CAF50;
  }
  /* Style the "active" element to highlight the current page */
  .TopNavBar a.active {
    background-color: #2196F3;
    color: white;
  }

.Logo {
  position: relative;
  margin: 20px -27px;
  height: 55px;
  left: -144px;
  top: -10px;
  width: auto;
  display:none;
}

.header{
  margin-left: 0px;
  float: left;
  font-size: 36px;
  top: 0;
  font-weight: bolder;
  color: #29b6f6;
  position: relative;
  top: -15px;
  left: -117px;
  width: auto;
}
  /*--------------------- Mobile Navigation Styles ---------------------*/
  /* @media(max-width:760px){
    .Logo {
      display: none;
    }
  } */
  @media(max-width:600px){
    .TopNavBar {
      width: 100%;
      max-width: 100%;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      left: -1px !important;
      position: fixed;
      top: -11px;
      left: 10px;
      height: 69px;
      left: 8px;
      position: fixed;
      z-index: 999;
      background: #fafafc;
  }
  .subtitle {
    font-size: 19px;
    border: 0;
    visibility: hidden;
    color: forestgreen;
    outline: none;
    display: inline-block;
    text-align: left;
    opacity: .85;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    position: relative;
    right: 548px;
    top: 70px;
    display: none;
  }
  
  .TopNavBar a, .TopNavBar input[type=text] {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .header{
    position: fixed;
    margin-left: 7px;
    /* float: left; */
    margin-top: 19px;
    font-size: 36px;
    top: 0;
    font-weight: bolder;
    color: #29b6f6;
    position: absolute;
    top: -58px;
    left: -10%;
    font-size: 26px;
    width: 500px;
    display: inline-block;
    background: white;
  }
  .TopNavBar input[type=text] {
    border: 1px solid #ccc;
    border: 1px solid #ccc;
    position: fixed;
    bottom: 6px;
    left: 0;
    display: block;
    max-width: 89%;
    margin-left: 5px;
}
.Logo {
  display: none;
}}
/*--------------------- Desktop Navigation Styles ---------------------*/
  @media(min-width: 1051px){
  .Logo {
    margin-left: 5px;
    font-size: 36px;
    top: 0;
    font-weight: bolder;
    color: #29b6f6;
    position: relative;
    top: -10px;
    left: -144px;
    width: auto;
    display: inline-block;
  }
  .header{
    margin-left: 5px;
    font-size: 36px;
    top: 0;
    font-weight: bolder;
    color: #29b6f6;
    position: relative;
    top: 11px;
    align-self: flex-start;
    left: -100px;
    justify-self: flex-start;
    width: auto;
    display: inline-block;
  }
  .subtitle {
    font-size: 19px;
    border: 0;
    color: forestgreen;
    visibility: hidden;
    outline: none;
    display: inline-block;
    text-align: left;
    opacity: .85;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    position: relative;
    right: 548px;
    top: 70px;
  }
  }

  /* Style the search box inside the navigation bar */
  /*.TopNavBar input[type=text] {
    padding: 6px;
    width: 30%;
    border: 1px solid black;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    font-size: 17px;
  }
  */
/*--------------------- Tablet Navigation Styles ---------------------*/
@media(max-width:1050px) and (min-width: 601px){
  .TopNavBar {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    left: -18%;
    position: fixed;
    top: 0;
    height: 70px;
    z-index: 11;
    background: #fafafc;
    }
  .subtitle {
    display: none;
  }
}
@media(max-width: 1050px) and (min-width:791px){
  .header {
    margin-left: 5px;
    font-size: 36px;
    top: 0;
    font-weight: bolder;
    color: #29b6f6;
    position: relative;
    top: 11px;
    align-self: flex-start;
    left: -21%;
    justify-self: flex-start;
    width: auto;
    display: inline-block;
  }
  .logo {
    position: relative;
    margin: 20px -27px;
    height: 55px;
    left: -140px !important;
    top: -8px;
    width: auto;
    display: none;
  }
}
@media(max-width: 790px) and (min-width:601px){
  .header {
    position: relative;
    left: -43px;
  }
  .logo {
    display: none;
  }
}

/* .header {
  font-size: 32px;
  width: 500px;
  background: white;
  position: fixed;
  background-size: auto;
  font-weight: bolder;
  color: #29b6f6;
  top: -7px;
  padding: 0 0 0 0;
  margin: -1px 0 0 0;
  right: -29%;
} */
  /* @media(max-width: 900px){
    .Logo {
      display: inline-block;
    } */


