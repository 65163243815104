
.CategorySelector {
  height: 75%;
  position: fixed;
  overflow-y: auto;
  /* direction: rtl; */
  top: 200px;
  z-index: 3;
  max-width: 236px;;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: start;
}
.CategoryButton {
  display: none;
}
.dropdown {
  height: 69%;
  position: fixed;
  overflow: auto;
  top: 162px;
  background: none;
  font-family: 'Baloo 2';
  font-size: 18px;
  font-weight: 600;
  border: none;
  z-index: 9;
  width: 251px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: start;
  margin: 0 -37px;
}
.lang_btn {
  border: none;
  outline: none;
  padding: 5px 9px;
  background: none;
  width: 210px;
  height: auto;
  margin-right: 0px;
  text-align: start; 
  font-family: 'Baloo 2';
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  cursor: grab;
  display: flex;
  flex-flow: row;
  position: relative;
  left: 3px;
  top: 5px;
} 
/* bold and make opacity 100 on hover */
.lang_btn:hover {
  font-weight: bolder;
  opacity: 1 !important;
}
 .icon {
  font-size: 2em;
  z-index: 9;
}

.mobile_icon {
  font-size: 20px;
  z-index: 9;
  padding-right: 5px;
  padding-left: 5px;
  top: 2px;
  position: relative;
}

.label {
  composes: lang_btn; 
  color: black;
  /* left: 45px;  */
}
.dropdown_icon {
  font-size: 18px;
  /* width: 36px; 
  height: 36px;  */
  margin-left: 5px;
  position: relative;
  z-index: 9;
  top: 2px;
}
/* category selector button default */
@media(min-width: 601px){
  .CategoryButton {
    display: none;
    top: 80px;
    font-family: 'Baloo 2';
    font-size: 18px;
    font-weight: 600;
  }
  .categorySelector_icon {
    color: rgb(255, 168, 52);
    opacity: 1;
    width: 33px;
  }
}
  /*---------- Tablet ------------*/
  @media(max-width:1074px) and (min-width: 600px){
    .lang_btn {
      border: none;
      outline: none;
      padding: 5px 10px;
      width: 10%;
      height: 40px;
      margin-right: 10px;
      text-align: start;
      font-size: 18px;
      line-height: 1;
      height: 47px;
      cursor: grab;
      display: flex;
      flex-flow: row;
      overflow-wrap: normal;
      border-radius: 30px;
      font-weight: 600;
      position: relative;
      left: 10px;
      top: 4px;
    }
    .CategorySelector{
      height:75%;
      position: fixed;
      overflow-y: auto;
      top: 200px;
      left: 8%;
      z-index: 9;
      max-width: 172px;
      display: inline-block;
      flex-flow: column;
      align-items: flex-start;
      text-align: start;
  }
    .dropdown{
      left: 6%;
      height: 80%;
    }
  }
  @media(max-width:655px) and (min-width:601px){
    .dropdown {
      left:1%;
    }
  }
  /*-----mobile-----*/
  @media(max-width: 600px) {
    .dropdown{
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      overflow-y: auto;
      left: 5px;
      padding: 1px 5px;
      background-color: seashell;
      border-radius: 25px;
      outline: none;
      min-width: 160px;
      /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
      z-index: 11;
      display: none;
    }
    .lang_btn {
      border: none;
      outline: none;
      width: 200px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin: 20px 7px;
      text-align: start;
      font-size: 18px;
      line-height: 1;
      height: 8px !important;
      cursor: grab;
      display: flex;
      flex-flow: row;
      overflow-wrap: normal;
      border-radius: 30px;
      font-weight: 600;
      position: relative;
      top: -15px;
      left: 2px;
   }
    .icon {
      font-size: 2em;
      z-index: 9;
      position: relative;
      top: 8px;
  }
    .CategoryButton {
      position: fixed;
      display: inline-block;
      background: none;
      font-family: 'Baloo 2';
      font-size: 17px;
      font-weight: 600;
      color: black;
      border: none;
      z-index: 9;
      left: -5px;
      top: 60px;
      font-size: 17px;
      overflow-x: hidden;
      margin-left: 0px;
    } 
    
    .CategorySection:hover .dropdown{
      display: flex;
      height: 73%;
      z-index:999;
      top: 114px;
      flex-direction: column;
      position: fixed;
    }

    /* .CategorySection:active .dropdown{
      display: none;
    } */
    
    .CategoryButton:hover {
      color: green;
      font-weight: bold;
    }
  }
@media(max-width: 365px){
  .CategoryButton {
    position: fixed;
    left: -20px;
    top: 43px;
  }
}