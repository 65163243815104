 @media(min-width:1051px){
.FeedItems {
    /* border: 2px solid #d6d6d6;
    border-radius: 20px;
    width: 400px;
    max-width: 458px;
    min-width: 280px;
    height: auto;
    margin-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    display: block;
    z-index: 1;
    position: relative;
    text-align: left !important;
} */
    /* border: 2px solid #d6d6d6; */
    box-shadow: rgba(60,64,67,.3) 0 1px 2px 0, rgba(60,64,67,.15) 0 1px 3px 1px;
    border-radius: 20px;
    width: 400px;
    max-width: 458px;
    min-width: 280px;
    height: auto;
    margin-top: 1%;
    margin-bottom: 12px;
    padding: 1% 2%;
    display: block;
    /* new z */
    z-index: 9;
    position: relative;
    text-align: left !important;
    background: white;
}
}
@media(max-width: 1050px) and (min-width: 601px){
.FeedItems {
    /* border: 2px solid #d6d6d6; */
    box-shadow: rgba(60,64,67,.3) 0 1px 2px 0, rgba(60,64,67,.15) 0 1px 3px 1px;
    border-radius: 20px;
    font-size: 12px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: auto;
    max-width: 500px;
    width: 418px;
    min-width: 279px;
    padding-left: 15px;
    justify-content: center;
    position: relative;
    right: 10px;
    top: 203px;
    margin-top: 10px;
    background: white;
}}
/* mobile */
@media(max-width:600px){
    .FeedItems {
        /* border: 2px solid #d6d6d6; */
        box-shadow: rgba(60,64,67,.3) 0 1px 2px 0, rgba(60,64,67,.15) 0 1px 3px 1px;
        border-radius: 20px;
        font-size: 12px;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: auto;
        margin-bottom: 8px;
        max-width: 500px;
        width: 90%;
        min-width: 279px;
        padding-left: 15px;
        justify-content: center;
        position: relative;
        left: 2%;
        top: 127px;
        background: white;
    }
    .phrase_title {
        display: block;
        font-weight: bold;
        margin-left: 20px;
        font-size: 21px;
        margin-left: 40px;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .ref_phrase {
        text-align: left;
        font-size: 22px;
        padding: 0px;
        margin-left: 42px;
        font-weight: normal;
        padding-top: 0px;
        margin-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
} 
/* Larger than mobile */
@media (min-width:601px) {
    .phrase_title {
        display: block;
        font-weight: bold;
        margin-left: 20px;
        font-size: 22px;
        margin-left: 40px;
        padding-left: 5px;
        margin-top: 6px;
        margin-bottom: 0;
    }
}

.headLine {
    left: 10px;
    text-align: left; 
    align-items: left;
}



.ref_phrase {
    text-align: left;
    font-size: 22px;
    padding: 0px;
    margin-left: 45px;
    font-weight: normal;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.transliteration {
    text-align: left;
    margin-left: 40px;
    font-weight: normal; 
    /* padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 0px;
    padding: 0; */
}

.speakerIcon {
    position: absolute;
    top: 17px;
    left: 18px;
    align-items: center;
    font-size: 20px;
    color: grey;
}

.speakerIcon:hover {
    color: red;
}

/* .checkIcon {
    display: none;
    float: right;
    padding-top: 0px;
    font-size: 20px;
    color: grey;
}

.checkIcon:hover {
    color: green;
    display: none;
} */