@media(min-width:1051px){
    .Footer_copyrightFooter1 {
        display:block;
    }
    .Footer_copyrightFooter2 {
        display: none;
    }
}
@media(max-width:1050px) and (min-width:600px){
    .Footer_copyrightFooter1 {
        font-size: 16px;
        position: relative;
        left: -21px;
        display: block;
    }
    .Footer_copyrightFooter2 {
        display:none;
    }
}
@media(max-width:600px){
    .Footer_copyrightFooter1 {
      display:none;
    }
    .Footer_copyrightFooter2 {
        display: block;
        position: relative;
    }
}
.Footer_privacy-policy {
    position: relative;
}

