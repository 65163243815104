.form_container {
    width: 276px;
    height: auto;
    background-color: #88e162;
    border-radius: 20px;
    border: 2px solid #69a94f;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 87%;
    max-width: 500px;
    top: 349px;
    right: 3%;
    /* height: 197px; */
    /* height: auto; */
    margin-bottom: 24px
  }
textarea#message1.formControl {
  width: 100%;
  left: -42px;
  position: relative;
  } 
  /* feedback */
  .head {
    font-size: 24px;
    margin-left: 10px;
    font-family: 'Baloo 2';
    color: black;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 8px;
    opacity: 1;
    text-align: center;
  }
  @media (max-width: 600px) {
     /* feedback */
  .head {
    font-size: 29px;
    margin-left: 10px;
    font-family: 'Baloo 2';
    color: black;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 8px;
    opacity: 1;
    text-align: center;
    }
  }

  .text_div {
      width: 100%;
  }

  textarea {
    width: 95%;
    border-radius: 5px;
    font-size: 16px;
    overflow-y: auto;
    background: rgb(255,255,255, 1);
  }

  .message {
      font-size: 14px;
      padding: 0px;
  }
  .submitButton {
    background-color: rgb(255,255,255, 1);
    position: relative;
    left: -5px;
    top: 2px;
    width: 100px;
    /* height: 27px; */
    height: auto;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid black;
    padding: 5px;
  }
  .submitButton:hover {
    background: #F0F0F0;
  }
  
  @media (max-width: 768px) {
    .form_group{
      width: 90%;
      margin-left: 18px;
    }
    .submitButton {
      left: 5px;
      top: 3px;
      padding: 5px;
    }
    .form_container__2 {
      width: 276px;
      /* height: 30%; */
      height: auto;
      background-color: #72bb53;
      border-radius: 20px;
      border: 2px solid #69a94f;
      /* overflow-y: scroll; */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px 16px;
      width: 267px;
      top: 349px;
      right: 3%;
      /* height: 197px; */
      margin-bottom: 24px;
  }
  }
  @media (max-width: 600px) {
    .submitButton{
      left: 5px;
      top: 5px;
      padding: 5px;
    }
    .form_container {
      /* height: 30%; */
      height: auto;
      background-color: #88e162;
      overflow: auto;
      border-radius: 20px;
      border: 2px solid #69a94f;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px 16px;
      width: 87%;
      top: 349px;
      max-width: 500px;
      right: 3%;
      /* height: 205px; */
      margin-left: 2px;
      margin-bottom: 24px;
  }}